<i18n src="@/locales/dental_implant.json" />
<i18n src="@/locales/articles-card-content.json" />
<i18n src="@/locales/card-content-dental-implant.json" />

<template>
  <div>
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section class="section">
      <div class="columns is-centered">
        <div class="column is-3">
          <ImanRoundedPictureBox
            :content="cardContentDentalImplant[0]"
            :service-class="roundedPicsClass"
          >
            {{ $t('more_info') }}
          </ImanRoundedPictureBox>
          <p class="content has-text-justified m-4">
            {{ $t('article_p1') }}
          </p>
        </div>
        <div class="column is-3">
          <ImanRoundedPictureBox
            :content="cardContentDentalImplant[1]"
            :service-class="roundedPicsClass"
          >
            {{ $t('more_info') }}
          </ImanRoundedPictureBox>
          <p class="content has-text-justified m-4">
            {{ $t('article_p2') }}
          </p>
        </div>
      </div>
    </section>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard
          :title="$t('collapse_title_1')"
        >
          <p>
            {{ $t('collapse_p1') }}
          </p>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_2')"
        >
          <p>
            {{ $t('collapse_p2_1') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_1')">
            {{ $t('more_info_1') }}
          </ImanMoreInfo>
          <p>
            {{ $t('collapse_p2_2') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_2')">
            {{ $t('more_info_2') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_3')"
        >
          <p>
            {{ $t('collapse_p3') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_3')">
            {{ $t('more_info_3') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_4')"
        >
          <p>
            {{ $t('collapse_p4') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_4')">
            {{ $t('more_info_4') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_5')"
        >
          <p>
            {{ $t('collapse_p5') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_5')">
            {{ $t('more_info_5') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanArticleSection from "@/components/ImanArticleSection";
  import ImanRoundedPictureBox from "@/components/ImanRoundedPictureBox";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {cardContentDentalImplantMixin} from "@/mixins/card-content-dental-implant-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";
export default {
  name: 'DentalImplant',
  components: {
    ImanRoundedPictureBox,
    ImanArticleSection
  },
  mixins: [
    articlesCardContentFilterMixin,
    cardContentDentalImplantMixin,
    metaMixin
  ],
  data () {
    return {
      articlesCardContentId: [212, 2241, 61, 2243],
      roundedPicsClass: {
        overlay: 'overlay-half-circle show-overlay-half-circle',
        element: 'element show-element'
      },
    }
  },
  computed: {
    openGraphImgPath () {
      return 'https://anveli.dental' + require('../../assets/img/thumbnail/MINIATURE_2.0.png')
    }
  },
}
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../assets/img/dental-implant/h1-implant-dentaire-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../assets/img/dental-implant/h1-implant-dentaire-t.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
      /* smartphone en mode portait */
      background-image: url("../../assets/img/dental-implant/h1-implant-dentaire-m.jpg");
    }
  }
  h3 {
    font-family: 'Comfortaa', cursive;
    text-align: center;
    font-weight: bold;
    overflow-wrap: break-word;
    margin-top: 1em;
  }
  .background-section {
    background-image: url("../../assets/img/dental-implant/faq-bck-img.jpg");
    background-repeat: no-repeat;
    min-height: 765px;
  }
</style>
