export const cardContentDentalImplantMixin = {
  computed: {
    cardContentDentalImplant () {
      let cardContentDentalImplant = []
      switch (this.$i18n.locale) {
        case 'en':
          cardContentDentalImplant = this.$i18n.messages.en.cardContentDentalImplant
          break
        case 'ru':
          cardContentDentalImplant = this.$i18n.messages.ru.cardContentDentalImplant
          break
        default:
          cardContentDentalImplant = this.$i18n.messages.fr.cardContentDentalImplant
      }
      return Object.values(cardContentDentalImplant)
        .filter(cardContent => cardContent)
    }
  }
}
